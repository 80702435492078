import { getLoadConfigCompany } from "services/actions/userAction"
import { useDispatch, useSelector } from "react-redux"

export const useCompany = () => {

	const dispatch = useDispatch()
	const { companyDataBasic } = useSelector((state) => state.users)

	const getBasicCompany = async () => {
		await dispatch(getLoadConfigCompany())
	}


	return {
		getBasicCompany,
        companyDataBasic
	}
}