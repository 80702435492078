import { useState, useEffect } from "react";
import MenuLeft from "components/Layout/MenuLeft";
import MenuBottom from "components/Layout/MenuBottom";
import MenuTop from "components/Layout/MenuTop";
import ModalForm from "components/Withdrawals/ModalForm";
import ModalSpinner from "components/Layout/ModalSpinner";
import retiro_new from "assets/images/retiro-new.png";
import user from "assets/images/user.png";
import { onPaste, validateOnlyNumber } from "../../utils/validationInput";
import { ToastContainer } from "react-toastify";
import { useLogin } from "hooks/Login/useLogin";
import { useModalWithDrawal } from "hooks/WithDrawals/useModalWithDrawal";
import { usePackageInfo } from "hooks/WithDrawals/usePackageInfo";
import { useCompany } from "hooks/Utils/useCompany"
import { menuItems } from "services/menu/";
import { Link } from "react-router-dom";

const Layout = ({ openModal, show, children }) => {

	const [ inact, setInact ] = useState(false);
	const [ searchRUT, setSearchRUT ] = useState('');
	const { actions, state } = useLogin(null)
	const { sucursales, formViewActive, nextForm, saveDestination, formPreOrden, resetValudeDestination, listFormaPagos, } = useModalWithDrawal(null)

	const { logoutUser } = actions

	const { actions: actionsSearch, state: {loadingInfoPackage} } = usePackageInfo()
	const { searchRUTHeader } = actionsSearch

	const { userData } = state

	const { getBasicCompany, companyDataBasic } = useCompany()

	const handleKeyDownExpand = (event) => {
		if (event.keyCode === 13) {
            searchRUTHeader(searchRUT);
        }
    };

	useEffect(() => {
		getBasicCompany()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	

	return (
		<div className="container-fluid">
			<div className="row">
				<MenuTop />
				<MenuLeft
					menuItems={menuItems}
					onCollapse={(inactive) => {
						setInact(inactive);
					}}
					logoutUser={logoutUser}
					company={companyDataBasic}
				/>
				
				<div className={`container-home w-100 ${inact ? 'inactive' : ''}`}>
					<div className="container-fluid container-main p-5">
						{
							userData?.internal_number!==null && (
								<div className="row">
									<div className="col-12">
										<p className="text-cta-corriente">Cuenta corriente: {userData?.internal_number}</p>
									</div>
								</div>
							)
						}
						<div className={`row row-dsk ${userData?.internal_number!==null && 'mt-0'}`}>
							<div className="col-12 col-dsk">
								<button 
									type="button" 
									className={`btn btn-secondary btn-top-menu w-160 btn-size-42 btn-new-retiro`}
									onClick={()=>openModal()}
								>
									<div className="btn-image">
										<img src={retiro_new} alt="Retiro Nuevo" width={17} heigth={17} />
									</div>
									Retiro nuevo
								</button>
								<div className="input-item">
									<input 
										type="text"
										className="input-main"
										placeholder="# Número de tracking"
										id="rut_header"
										name="rut_header"
										defaultValue={searchRUT}
										onChange={(event) => setSearchRUT(event.target.value)} 
										onKeyDown={(event) => handleKeyDownExpand(event)} 
										onPaste={(event) => onPaste(event)}				
										onKeyPress={(event) => validateOnlyNumber(event)}
										maxLength={20}
									/>
									<span className="input-main--icon"></span>
									<span className="input-main--delivery"></span>
								</div>
								<div className="d-flex options-icon">
									<div className="option-item__icon">
										<Link to="/perfil">
											<img src={user} alt="Configuración" width={18} heigth={18} />
										</Link>
									</div>
								</div>
							</div>
						</div>
						{children}
					</div>
				</div>
				<MenuBottom 
					menuItems={menuItems}
				/>
			</div>
			<ToastContainer />
			{show && <ModalForm 
					show={show} 
					handleClose={openModal} 
					formName={formViewActive} 
					sucursales={sucursales}
					nextForm={nextForm}
					saveDestination={saveDestination}
					multiple_destinatation={formPreOrden?.multiple_destinatation}
					resetValudeDestination={resetValudeDestination}
					listFormaPagos={listFormaPagos}
					/> 
			}
			{loadingInfoPackage && 
	    		<ModalSpinner 
		    		spinnerShow={loadingInfoPackage}
		    		description="Consultando el retiro..."
		    	/>
		    }
			
		</div>
	);
};

export default Layout;