import { useState } from "react"
import { createCustomer, validateTokenLink } from "services/actions/userAction"
import { searchCustomer } from "services/actions/customerAction"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom";
import { validarRut } from "utils/ToolRUT/"
import { getPhone } from "utils/globals";
import { phone } from "phone";

const RUT_MESON = process.env.REACT_APP_CLIENTE_MESON;

export const useRegisterCustomer = (toast) => {

	const dispatch = useDispatch()
	const { companyDataBasic } = useSelector((state) => state.users)
	const location = useLocation();
	const [ isSpinner, setIsSpinner] = useState(true)
	const [ isReadOnlyBasic, setIsReadOnlyBasic ] = useState(true)
	const [ isReadOnlyRut, setIsReadOnlyRut ] = useState(true)
	const [ isReadOnlyPass, setIsReadOnlyPass ] = useState(true)

	const [ isLoading, setIsLoading ] = useState(false)
	const [ isSuccess, setIsSuccess ] = useState(false)
	const [ isDisabled, setIsDisabled ] = useState(false)
	const [ tokenUser, setTokenUser ] = useState(false)
	const [ isSuccessToken, setIsSuccessToken ] = useState(false)



	const { register, handleSubmit, errors, setError, setValue, control, getValues, reset } = useForm({
		mode: "onChange",
		defaultValues: {
			rut: "",
			r_email: "",
			password: ""
		}
	})

	const onSubmit = async (data, token) => {
		try{
			
			if(!isDisabled){
				toast.error("Debe aceptar los términos y condiciones", {position: toast.POSITION.TOP_RIGHT})
				return false
			}
			setIsLoading(true)

			const responseRut = validarRut(data.rut)
			if(responseRut?.status===false){
				setError("rut", { type: "manual", message: responseRut?.message})
				setIsLoading(false)
				return false
			}else{
				setError("rut", { type: "manual", message: ""})
			}

			let regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/; //eslint-disable-line
			const isValidEmail = regex.test(data.r_email)
            if(!isValidEmail){
                setValue("r_email", "")
                setError("r_email", { type: "manual", message: `El e-mail ingresado es incorrecto`})
                setIsLoading(false)
            	return false
            }

            if(data.telephone.length<9){
				setError("telephone", { type: "manual", message: `El número ingresado es incorrecto`})
				setIsLoading(false)
            	return false
            }

            regex=/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g; //eslint-disable-line
			const isValidTelephone = regex.test(companyDataBasic?.country?.code+data.telephone)
            if(!isValidTelephone){
                setValue("telephone", "")
				setIsLoading(false)
            	return false
            }

            if(data.password.length<6){
				setIsLoading(false)
				toast.error("Las contraseñas deben ser mayor a 6 caracteres", {position: toast.POSITION.TOP_RIGHT})
				return false
			}

			if(data.repPassword.length<6){
				setIsLoading(false)
				toast.error("Las contraseñas deben ser mayor a 6 caracteres", {position: toast.POSITION.TOP_RIGHT})
				return false
			}

            if(data.password!==data.repPassword){
				setIsLoading(false)
				toast.error("Las contraseñas deben ser iguales", {position: toast.POSITION.TOP_RIGHT})
				return false
			}
		
			let body = {
				customer: {
					rut: data?.rut,
					full_name: data?.fullname?.toUpperCase(),
					phone_number: `${companyDataBasic?.country?.code}${data?.telephone}`,
					email: data?.r_email?.toLowerCase(),
					address: data?.r_address,
				},
				password: data?.password,
				token: tokenUser
			}
		
			let response = await dispatch(createCustomer(body))
			
			setIsLoading(false)

			if(response.status){
				setIsSuccess(true)
			}else{
				toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
			}

		}catch(error){
			setIsLoading(false)
			toast.error("Hubo un error, intentelo nuevamente", {position: toast.POSITION.TOP_RIGHT})
		}
	}

	const getTokenFromQuery = () => {
		const queryParams = new URLSearchParams(location.search);
		return { 
			company: queryParams.get('company'),
			token: queryParams.get('token')
		};
	  };

	const getVerifiedToken = async () => {
		setIsSpinner(true)
		const { company, token } = getTokenFromQuery();
	
		setTokenUser(token)
		const result = await dispatch(validateTokenLink(company, token))
		setIsSuccessToken(result?.status)
		setIsSpinner(false)
		if(result?.data?.customer!==null){
		
			reset({
				rut: result?.data?.customer?.rut,
				fullname: result?.data?.customer?.full_name ?? "",
				telephone: getPhone(result?.data?.customer?.phone_number, result?.data) ?? "",
				r_email: result?.data?.customer?.email ?? "",
				r_address: result?.data?.customer?.address ?? ""
			})
		}
		setIsReadOnlyRut(result?.data?.customer!==null ? true : false)
		setIsReadOnlyBasic(true)
		setIsReadOnlyPass(result?.data?.customer!==null ? false : true)
	}

	const searchCustomerByRUT = async (value) => {
		setIsReadOnlyBasic(true)
		setIsReadOnlyPass(true)

		if (value.length > 0) {
		  const isValid = validarRut(value);
		  if (!isValid.status) {
			toast.error(isValid.message, { position: toast.POSITION.TOP_RIGHT });
			return false;
		  }
		  if (value === RUT_MESON) {
			toast.error("No se puede registrar con ese RUT", {
			  position: toast.POSITION.TOP_RIGHT,
			});
			return false;
		  }
		  const response = await dispatch(
			searchCustomer({ search_type: "rut_cliente", value, company_id: companyDataBasic?.id })
		  );

		  let telefono = "";
		 
			if (
			  response.data?.phone_number !== null &&
			  response.data?.phone_number !== ""
			) {
			  telefono = getPhone(response.data?.phone_number, companyDataBasic);
			}
			reset({
				rut: value,
				fullname: response?.data?.full_name ?? "",
				telephone: telefono ?? "",
				r_email: response?.data?.email ?? "",
				r_address: response?.data?.address ?? ""
			})
			setIsReadOnlyRut(false)
			setIsReadOnlyBasic(false)
			setIsReadOnlyPass(false)
	 
		}
	};

	const handleBlurRut = async (event) => {
		if (event.target.value.length > 0) {
		  await searchCustomerByRUT(event.target.value);
		}
	  };
	
	  const onKeyDownFactura = async (event) => {
		if (event.keyCode === 13) {
		  event.preventDefault();
		  await handleBlurRut(event);
		}else{
			setIsReadOnlyBasic(true)
			setIsReadOnlyPass(true)
			setValue('fullname', '')
			setValue('telephone', '')
			setValue('r_email', '')
			setValue('r_address', '')
		}
	  };

	  const validatePhone = (value) => {
		if (
		  !phone(`${companyDataBasic?.country?.code}${value}`, {
			country: companyDataBasic?.country?.iso,
			validateMobilePrefix: false,
		  }).isValid
		) {
		  return "El número ingresado no es válido";
		}
	  };

	  const validatePassword = (value) => {
		if (value !== getValues("password")) {
		  return "Las contraseña deben ser iguales";
		}
	  };
    

	const state = {
		isLoading,
		isSuccess,
		isDisabled,
		companyDataBasic,
		isSpinner,
		isReadOnlyBasic,
		tokenUser,
		isSuccessToken,
		isReadOnlyRut,
		isReadOnlyPass
	}


	const formElement = {
		onSubmit,
		register, 
		handleSubmit, 
		errors,
		control,
		setError,
		setValue,
		getValues
	}

	const actions = {
		setIsDisabled,
		getVerifiedToken,
		onKeyDownFactura,
		handleBlurRut,
		validatePhone,
		validatePassword
	}

	return {
		state,
		formElement,
		actions		
	}
}