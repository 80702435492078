import API from 'services/api/index';
import { 
    LOGIN,
    LOGOUT,
	FORGOT_PASSWORD,
	DATA_MASTER_COMPANY
} from 'services/types/userType';
import endPoints from 'services/routes/';

export const requestSuccess = (type, payload) => {
    return { type, payload };
};

export const validateTokenLink = (company, token) => async (dispatch, getState) => {
	try {
		const body = { company, token }
	  let response = await API.post(
		endPoints.auth.verifyAuthorized,
		body
	  );

	  const { status, message, data } = response.data
	  return { status, message, data };
	} catch (error) {
	  return { status: false, message: "Error al validar el token" };
	}
  };

  

export const validateLoginUser = (body) => async (dispatch, getState) => {
	try{

		let response = await API.post(endPoints.auth.verifiedLogin, body);

		const { status, message, data } = response.data

		let listItem = data;
		let textDialog = "Para continuar, primero haga clic en la casa matriz que desee utilizar para acceder al sistema"

		if(data?.length===1){
			if(data[0].accountNumbers?.length>1){
				listItem = data[0].accountNumbers
				textDialog = "Para continuar, haga clic en el código interno que desee utilizar para acceder al sistema."
			}			
		}

		return { status, message, data: listItem, textDialog };
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const loginUser = (body) => async (dispatch, getState) => {
	try{

		let response = await API.post(endPoints.auth.login, body);

		const { status, message, token, data } = response.data
		if(status && data===null){
			localStorage.setItem("user", token);
			
			let userData = JSON.parse(atob(token))
		
		    const payload = {
				rut: body.rut,
				fullName: userData?.full_name,
				token: userData?.token,
				address: userData?.address,
				telephone: userData?.phone_number,
				email: userData?.email,
				sucursal_id: "",
				comuna_id: "",
				company_id: userData?.company_id,
				internal_number: userData?.internal_number,
				customer_company_id: userData?.customer_company_id
			}

		    dispatch(requestSuccess(LOGIN, payload));
		}

		return { status, message, data };
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const logout = (body) => async (dispatch, getState) => {
	try{
		let response = await API.get(endPoints.auth.logout)

		const { status, message } = response.data

		if(status){
			localStorage.clear()
			const payload = {
				rut: "",
				fullName: "",
				token: "",
				typeClient: "",
				address: "",
				telephone: ""
			}
			dispatch(requestSuccess(LOGOUT, payload))
		}
		return { status, message }
	}catch(error){
		return {status: false, message:error}
	}
};

export const getConfigCompany = () => async (dispatch, getState) => {
	try {
		const {  userData } = getState().users

	  let response = await API.get(endPoints.company.config(userData?.company_id));
	  const { status, message, data } = response.data;
	  localStorage.setItem("company", JSON.stringify(data));
	  dispatch(requestSuccess(DATA_MASTER_COMPANY, data));
	  return { status, message };
	} catch (error) {
	  return { status: false, message: error };
	}
  };
  
  export const getLoadConfigCompany = () => async (dispatch, getState) => {
	try {
	
	  const serializedData = typeof localStorage.getItem("company")!=="undefined"  ? JSON.parse(localStorage.getItem("company")) : null;
	 
	  if (serializedData?.hasOwnProperty("country")) {
		dispatch(requestSuccess(DATA_MASTER_COMPANY, serializedData));
	  } else {
		const {  userData } = getState().users

		let response = await API.get(endPoints.company.config(userData?.company_id));
		const { status, message, data } = response.data;
		localStorage.setItem("company", JSON.stringify(data));
		dispatch(requestSuccess(DATA_MASTER_COMPANY, data));
		return { status, message };
	  }
	} catch (error) {
	
	  return { status: false, message: error };
	}
  };

export const createCustomer = (body) => async (dispatch, getState) => {
	try{
		let response = await API.post(endPoints.customer.add, body);

		const { status, message } = response.data

		return { status, message };
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const forgotPassword = (body) => async (dispatch, getState) => {
	try{
		let response = await API.post(endPoints.password.forgot, body);

		const { status, message } = response.data

		if(status){
			const payload = {
				email: body.email,
				rut: body.rut
			}
			dispatch(requestSuccess(FORGOT_PASSWORD, payload))
		}		

		return { status, message };
		
    }catch(error){
      return {status: false, message:error}
    }
};


export const codeResetPassword = (body) => async (dispatch, getState) => {
	try{
		const {  emailForgot, rutForgot } = getState().users

		const data = { 
			email: emailForgot, 
			code: body.code,
			rut: rutForgot
		}

		let response = await API.post(endPoints.password.code, data);

		const { status, message } = response.data

		return { status, message };
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const resetNewPassword = (body) => async (dispatch, getState) => {
	try{
		const {  emailForgot, rutForgot } = getState().users

		const data = { 
			email: emailForgot, 
			password: body.password,
			rut: rutForgot
		}

		let response = await API.post(endPoints.password.reset, data);

		const { status, message } = response.data

		if(status){
			const payload = ""
			dispatch(requestSuccess(FORGOT_PASSWORD, payload))
		}		

		return { status, message };
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const resetPasswordProfile = (body) => async (dispatch, getState) => {
	try{
		const {  rut } = getState().users

		const data = { 
			rut, 
			passwordCurrent: body.passwordCurrent,
			password: body.password
		}

		let response = await API.post(endPoints.profile.password, data);

		const { status, message } = response.data

		return { status, message };
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const updateProfile = (body) => async (dispatch, getState) => {
	try{
		const {  rut } = getState().users

		const data = { 
			rut, 
			...body
		}

		let response = await API.post(endPoints.profile.client, data);

		const { status, message } = response.data

		if(status){		
			let userData = JSON.parse(atob(localStorage.getItem("user")))

			let payload = {
				internal_number: userData?.internal_number,
				fullName: body.fullname,
				rut: body.rut,
				address: body.address,
				telephone: body.telephone,
				email: body.email,
				token: userData?.token
			}

			localStorage.setItem("user", btoa(JSON.stringify(payload)));
			
		    dispatch(requestSuccess(LOGIN, payload));
		}

		return { status, message };
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const searchClientByCode = (body) => async (dispatch, getState) => {
	try{

		let response = await API.post(endPoints.auth.bycode, body);

		const { status, message, data } = response.data

		return { status, message, data };
		
    }catch(error){
      return {status: false, message:error}
    }
};