import { useState } from "react"
import {  nextFormPreOrden, saveFromPackage, resetDestination, createRetiro, assignOrdenByPackage,
	updateAssignFormRetiro, updateConfigForm } from "services/actions/withdrawalAction"
import { useDispatch, useSelector } from "react-redux"
import { formatDataRetiro } from 'utils/ToolPreOrden/DataInsertAndUpdate'

export const useMultipleDestination = (toast) => {

	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [ listSendOrden, setListSendOrden] = useState([])
	const [ viewList, setViewList ] = useState(true)

    const { preorden, users } = useSelector((state) => {
		return {
			preorden: state.preorden,
			users: state.users
		}
	})

	const { infoDestination, configFormData, formPreOrden } = preorden
	const { userData } = users;

	const sendFormCompleted =  async (event) => {
		try{
			setViewList(false)
			setListSendOrden(configFormData)
			setLoading(true)
            dispatch(saveFromPackage())

			let status = []
			let preordenes = []

			let formInitial = formPreOrden

			const remitente = {
				rut_remitente: formInitial.rut_remitente,
				razon_social_remitente: formInitial.razon_social_remitente,
				direccion_retiro: formInitial.direccion_retiro,
				telefono_remitente: formInitial.telefono_remitente,
				email_remitente: formInitial.email_remitente,
				comuna_origen_id: formInitial.comuna_origen_id,
				sucursal_origen_id: formInitial.sucursal_origen_id,
				observacion: formInitial?.observacion,
				codigo_interno: userData?.internal_number
			}
	

			const dataCliente = formatDataRetiro(remitente, formInitial?.destinations)
		
			const infoRetiro = {
				direccion: formInitial?.direccion_retiro,
				oficina_id: formInitial?.comuna_origen_id,
				remitente: dataCliente?.cliente,
				observacion: dataCliente?.observacion,

			}

			const response = await dispatch(createRetiro(infoRetiro))

			if(response.status){
				let dataRetiro = response?.data;

				let updateListSend = configFormData
	
				for(let index in configFormData){
					const { dataDestino } = formatDataRetiro(remitente, configFormData[index]?.destionationsFormat)
			
					const infoOrden = {
						...infoRetiro,
						destinatarios: dataDestino
					}
			
					const result = await dispatch(assignOrdenByPackage(infoOrden, response?.data?.id))
		
					status.push(result?.status)
					if(result?.status){
						preordenes= [...preordenes, ...result?.data]
						configFormData[index]={...configFormData[index], total: result?.data?.length}
						updateListSend[index] = {...configFormData[index], total: result?.data?.length}
						dispatch(updateConfigForm(configFormData))
						setListSendOrden(listSendOrden => updateListSend)
					}

					
				}
			
				setLoading(false)
				const itemFalse = status?.filter(item => !item)
			
				if(itemFalse?.length===0){
					dataRetiro= {
						...dataRetiro,
						ordenes: preordenes
					}

					dispatch(updateAssignFormRetiro(dataRetiro))

					dispatch(nextFormPreOrden("completed"))
					dispatch(resetDestination())
				}
			
			}else{
				setLoading(false)
				toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
			}       
			
		}catch(error){
			toast.error("Hubo un error, intentelo nuevamente", {position: toast.POSITION.TOP_RIGHT});
		}
	}

	const lastFormPreOrden = (nameForm) => {
		dispatch(nextFormPreOrden(nameForm))
	}

	return {
		lastFormPreOrden,
        infoDestination,
		sendFormCompleted,
		loading,
		configFormData,
		listSendOrden,
		viewList
	}
}