import { useState, useEffect } from "react";
import MenuItem from "components/Layout/MenuItem";
import logout from "assets/images/menu/logout.png";

const MenuLeft = ({ onCollapse, menuItems, logoutUser, company }) => {

	const [ inactive, setInactive ] = useState(false);

	useEffect(() => {
		if(inactive){
			document.querySelectorAll(".sub-menu").forEach(el => {
				el.classList.remove('active');
			});
		}
		onCollapse(inactive);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inactive]);

	const handleKeyDown = (e) =>{
        if (e.keyCode === 13) {
            setInactive(!inactive);
        }
    };

	return (
		<div className={`side-menu ${inactive ? 'inactive' : ''}`}>
			<div className="top-section">
				<div className="logo">
					<img src={company?.logo} alt="Logo" />
				</div>
				<div aria-hidden="true" onClick={()=>setInactive(!inactive)} onKeyDown={(e) =>handleKeyDown(e)} className="toogle-menu-btn">
					{!inactive ?
						<i className="bi bi-arrow-left-square-fill"></i>
						: <i className="bi bi-arrow-right-square-fill"></i>
					}
				</div>
			</div>

			

			<div className="main-menu">
				<ul>
					{
						menuItems.map((menuItem, index) => (
							<MenuItem 
								key={index} 
								{...menuItem} 
								toUrl={menuItem.to}
								onClick={()=> {
									if(inactive){
										setInactive(false);
									}
								}}
							/>
						))
					}
				</ul>
			</div>

			<div className="sider-menu-footer">
				<div className="menu-footer">
					<li className="d-flex">
						<button onClick={(event)=>logoutUser(event)} className="menu-item d-flex">
							<div className="menu-icon mr-3">
								<img src={logout} alt="Reclamos" width={14} heigth={14} />
							</div>
							<span>Cerrar sesión</span>
						</button>
					</li>
				</div>
			</div>
		</div>
	);
};

export default MenuLeft;
