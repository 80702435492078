import API from 'services/api/index';
import endPoints from 'services/routes/';

export const requestSuccess = (type, payload) => {
  return { type, payload };
};


export const searchCustomer = (body) => async (dispatch, getState) => {
  try {
    const dataSend = {
      ...body,
      value: body.value.toLowerCase(),
    };

    let response = await API.post(endPoints.customer.search, dataSend);
    const { status, message, data, created } = response.data;
    return { status, message, data, created };
  } catch (error) {
    return { status: false, message: error };
  }
};
