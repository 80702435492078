import styles from "styles/Retiro.module.css";
import { useFormClaim } from "hooks/Claims/useFormClaim"


const FormClaim = ({ toast, title, subtitle, type }) => {

	const { onSubmit, register, handleSubmit, errors, isLoading, resetInput} = useFormClaim(toast)

	return (
		<form onSubmit={handleSubmit(data => onSubmit(data, type))}>
			<section className="mt-5">
				<div className="row mb-4">
				    <div className="col-12">
				      	<label className={styles["form-withdrawals__label"]} htmlFor="claim_reason">{title}</label>
				      	<input 
				      		type="text" 
				      		className="form-control" 
				      		id="claim_reason" 
				      		name="claim_reason"
				      		ref={register({required: {value: true, message: 'Requerido'}})}
				      	/>
				      	{(errors.claim_reason && errors.claim_reason.message!=="") && (
					    	<p className="text-danger mb-4">
					      		{errors?.claim_reason?.message}
					    	</p>
					    )}
				    </div>
				    <div className="col-12 mt-5">
				      	<label className={styles["form-withdrawals__label"]} htmlFor="detail_claim">{subtitle}</label>
				      	<textarea 
				      		className="form-control" 
				      		id="detail_claim" 
				      		name="detail_claim"
				      		rows={6}
				      		cols={6}
							maxLength={255}
				      		ref={register({required: {value: true, message: 'Requerido'}})}
				      	></textarea>
				      	{errors.detail_claim && (
					    	<p className="text-danger mb-4">
					      		{errors?.detail_claim?.message}
					    	</p>
					    )}
				    </div>
				</div>
				<div className={styles["btn-modal"]}>
				  	<button 
						type="button"
				  		className="btn btn-outline-primary btn-size-33 btn-block mr-3" 
						onClick={() => resetInput()}
						disabled={isLoading}
				  	>
			            Cancelar
			        </button>
			        <button 
			        	type="submit"
			        	className="btn btn-secondary btn-size-33 btn-block mt-0" 
						disabled={isLoading}
			        >
			            {isLoading ? "Enviando..." : "Enviar" }
			        </button>
				</div>
			</section>
		</form>
	)
}

export default FormClaim