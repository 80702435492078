const API = process.env.REACT_APP_API_URL;

const endPoints = {
  auth: {
    login: `${API}/auth/web/login`,
    verifiedLogin: `${API}/auth/web/verified-login`,    
    logout: `${API}/auth/web/logout`,
    bycode: `${API}/auth/web/client/code`,
    verifyAuthorized: `${API}/auth/verify-customer`,
  },
  consultationAndComplaint: {
    sendForm: `${API}/web/claims/send-form`,
    list: `${API}/web/claims`,
    getMessage: `${API}/web/claims/conversation`,
    sendRepley: `${API}/web/claims/send-repley`,
    updateMessage: `${API}/web/claims/message-status`
  },
  company: {
    config: (companyId) => `${API}/web/companies/${companyId}`,
  },
  customer: {
    search: `${API}/web/customers`,
    add: `${API}/web/customers/create`,
  },
  retiros: {
    addRetiros: `${API}/web/packages`,
    getListAll: (page, limit) => `${API}/web/packages/all?page=${page}&limit=${limit}`,
    previewRetiro: (id, page) =>`${API}/web/packages/print/${id}?page=${page}`,
    previewTicketRetiro: (id) => `${API}/web/packages/ticket/${id}`,
    countPackage: `${API}/web/packages/send`,
    printExcelPdf: `${API}/web/packages/download`,
    getInfoPackage: (numero_retiro) =>`${API}/web/packages/${numero_retiro}/search`,
    downloadInfo: `${API}/web/packages/informe`,
    createRetiros: `${API}/web/packages/create`,
    assignOrden: (id) =>`${API}/web/packages/${id}/assign/orden`,    
  },
  orders: {
    getListAll: (page, limit) => `${API}/web/orders/all?page=${page}&limit=${limit}`,
    printExcelPdf: `${API}/web/orders/download`,
    notification: (page, limit) => `${API}/web/orders/notification?page=${page}&limit=${limit}`,
  },
  inovice: {
  	getInoviceClient: (page, limit) => `${API}/web/invoice/list?page=${page}&limit=${limit}`,
  	getInoviceCompany: (page, limit) => `${API}/web/invoice/company?page=${page}&limit=${limit}`
  },
  password: {
    forgot: `${API}/auth/web/password/reset`,
    code: `${API}/auth/web/password/code`,
    reset: `${API}/auth/web/password/new`
  },
  profile: {
    password: `${API}/web/profile/password`,
    client: `${API}/web/profile/client`,
  },
  office: {
    list: (companyId) => `${API}/web/cities/${companyId}`,
  },
  
  formaPago: {
    list: `${API}/web/forma-pagos`,
  },
};

export default endPoints;