import { useState } from "react"
import { forgotPassword, codeResetPassword, resetNewPassword } from "services/actions/userAction"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
//import md5 from "md5";

export const usePassword = (toast) => {

	const dispatch = useDispatch()
	const [ isLoading, setIsLoading ] = useState(false)
	const [ nextForm, setNextForm ] = useState("form-forgot-password")

	const { register, handleSubmit, errors, setError, setValue, control } = useForm()

	const onSubmit = async (data, event) => {
		try{
  
			setIsLoading(true)

			let response = ""

			if(nextForm==="form-forgot-password"){
				let regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/; //eslint-disable-line
				const isValidEmail = regex.test(data.email)
				if(!isValidEmail){
					setValue("email", "")
					setError("email", { type: "manual", message: `El e-mail ingresado es incorrecto`})
					setIsLoading(false)
					return false
				}

				let body = {
					email: data?.email.toLowerCase(),
					rut: data?.rut
				}
	
				response = await dispatch(forgotPassword(body))
			}else if(nextForm==="form-code-password"){

				response = await dispatch(codeResetPassword(data))
				
			}else if(nextForm==="form-new-password"){

				if(data.password.length<6){
					setIsLoading(false)
					toast.error("Las contraseñas deben ser mayor a 6 caracteres", {position: toast.POSITION.TOP_RIGHT})
					return false
				}
	
				if(data.repPassword.length<6){
					setIsLoading(false)
					toast.error("Las contraseñas deben ser mayor a 6 caracteres", {position: toast.POSITION.TOP_RIGHT})
					return false
				}
	
				if(data.password!==data.repPassword){
					setIsLoading(false)
					toast.error("Las contraseñas deben ser iguales", {position: toast.POSITION.TOP_RIGHT})
					return false
				}

				let body = { password: data?.password}
				response = await dispatch(resetNewPassword(body))
			}

			setIsLoading(false)

			if(response.status){
				if(nextForm==="form-forgot-password"){
					setNextForm("form-code-password")
				}else if(nextForm==="form-code-password"){
					setNextForm("form-new-password")
				}else if(nextForm==="form-new-password"){
					setNextForm("form-message")
				}					
			}else{
				toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
			}

		}catch(error){
			setIsLoading(false)
			toast.error("Hubo un error, intentelo nuevamente", {position: toast.POSITION.TOP_RIGHT})
		}
	}


	


	const state = {
		isLoading,
		nextForm	
	}


	const formElement = {
		onSubmit,
		register, 
		handleSubmit, 
		errors,
		control,
		setError,
		setValue
	}

	return {
		state,
		formElement	
	}
}